<template>
  <div class="embed-responsive embed-responsive-16by9">
    <iframe class="embed-responsive-item" :src="src" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  props: ["src"],
  mounted() {
    console.log(this.src);
  }
};
</script>